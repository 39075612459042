var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('edit-component',{attrs:{"name":_vm.title},scopedSlots:_vm._u([{key:"inputs",fn:function(){return [_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"formRequest",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{attrs:{"label":_vm.$t('Global.term'),"label-for":"term-input"}},[_c('validation-provider',{attrs:{"name":"term","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.options.terms,"clearable":false,"input-id":"term-input","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local +' '+ option.code : option.name +' '+ option.code)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var name_local = ref.name_local;
var code = ref.code;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[(_vm.$i18n.locale == 'ar')?_c('strong',[_vm._v(_vm._s(name_local)+" "+_vm._s(code)+" ")]):_c('strong',[_vm._v(_vm._s(name)+" "+_vm._s(code))])])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noMatching'))+" ")]},proxy:true}],null,true),model:{value:(_vm.formRequest.term_id),callback:function ($$v) {_vm.$set(_vm.formRequest, "term_id", $$v)},expression:"formRequest.term_id"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}],null,false,924388731)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Minimum GPA'),"label-for":"min_gpa"}},[_c('validation-provider',{attrs:{"name":"gpa","rules":"required|between:0,4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"min_gpa","type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formRequest.min_gpa),callback:function ($$v) {_vm.$set(_vm.formRequest, "min_gpa", $$v)},expression:"formRequest.min_gpa"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{attrs:{"label":_vm.$t('Global.stage_group'),"label-for":"stage-group"}},[_c('validation-provider',{attrs:{"name":"stage group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.options.stage_groups,"clearable":false,"input-id":"stage-group","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local : option.name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var name_local = ref.name_local;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[(_vm.$i18n.locale == 'ar')?_c('strong',[_vm._v(_vm._s(name_local)+" ")]):_c('strong',[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noMatching'))+" ")]},proxy:true}],null,true),model:{value:(_vm.formRequest.stage_group_id),callback:function ($$v) {_vm.$set(_vm.formRequest, "stage_group_id", $$v)},expression:"formRequest.stage_group_id"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}],null,false,875306585)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{attrs:{"label":_vm.$t('Global.stage_code'),"label-for":"stage-code"}},[_c('validation-provider',{attrs:{"name":"stage code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.options.stage_codes,"clearable":false,"input-id":"stage-code","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local : option.name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var name_local = ref.name_local;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[(_vm.$i18n.locale == 'ar')?_c('strong',[_vm._v(_vm._s(name_local)+" ")]):_c('strong',[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noMatching'))+" ")]},proxy:true}],null,true),model:{value:(_vm.formRequest.stage_code_id),callback:function ($$v) {_vm.$set(_vm.formRequest, "stage_code_id", $$v)},expression:"formRequest.stage_code_id"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}],null,false,3069395755)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{attrs:{"label":_vm.$t('Global.faculty'),"label-for":"faculty-input"}},[_c('validation-provider',{attrs:{"name":"faculty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filter":_vm.fuseSearch,"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.options.faculties,"clearable":false,"input-id":"faculty-input","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local : option.name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var name_local = ref.name_local;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[(_vm.$i18n.locale == 'ar')?_c('strong',[_vm._v(_vm._s(name_local)+" ")]):_c('strong',[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noMatching'))+" ")]},proxy:true}],null,true),model:{value:(_vm.formRequest.faculty_id),callback:function ($$v) {_vm.$set(_vm.formRequest, "faculty_id", $$v)},expression:"formRequest.faculty_id"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}],null,false,3682896332)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{attrs:{"label":_vm.$t('bylaw'),"label-for":"bylaw-input"}},[_c('validation-provider',{attrs:{"name":"bylaw","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filter":_vm.fuseSearch,"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.options.bylaws,"clearable":false,"input-id":"bylaw-input","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local : option.name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var name_local = ref.name_local;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[(_vm.$i18n.locale == 'ar')?_c('strong',[_vm._v(_vm._s(name_local)+" ")]):_c('strong',[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noMatching'))+" ")]},proxy:true}],null,true),model:{value:(_vm.formRequest.bylaw_id),callback:function ($$v) {_vm.$set(_vm.formRequest, "bylaw_id", $$v)},expression:"formRequest.bylaw_id"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}],null,false,1797378440)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{attrs:{"label":_vm.$t('program'),"label-for":"program-input"}},[_c('b-form-checkbox',{staticStyle:{"font-size":"0.2rem","padding-bottom":"1rem"},attrs:{"value":true},model:{value:(_vm.allPrograms),callback:function ($$v) {_vm.allPrograms=$$v},expression:"allPrograms"}},[_vm._v(" All Programs ")]),_c('v-select',{attrs:{"filter":_vm.fuseSearch,"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.options.programs,"clearable":false,"multiple":"","disabled":_vm.allPrograms,"input-id":"program-input","reduce":function (val) { return val.id; }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$i18n.locale == "ar" ? option.name_local : option.name)+" ")]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var name_local = ref.name_local;
return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[(_vm.$i18n.locale == 'ar')?_c('strong',[_vm._v(_vm._s(name_local)+" ")]):_c('strong',[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noMatching'))+" ")]},proxy:true}],null,false,4238391510),model:{value:(_vm.formRequest.programs),callback:function ($$v) {_vm.$set(_vm.formRequest, "programs", $$v)},expression:"formRequest.programs"}})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[(_vm.options)?_c('b-form-group',{staticStyle:{"padding-top":"2.4rem"},attrs:{"label":_vm.$t('Date From/To'),"label-for":"date-input"}},[_c('validation-provider',{attrs:{"name":"range date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control primary",attrs:{"config":{ mode: 'range'},"id":"date-input"},model:{value:(_vm.formRequest.rangeDate),callback:function ($$v) {_vm.$set(_vm.formRequest, "rangeDate", $$v)},expression:"formRequest.rangeDate"}}),_c('ValidationErrors',{attrs:{"frontend-errors":errors}})]}}],null,false,1745873813)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Global.submit'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('Global.cancel'))+" ")])],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }